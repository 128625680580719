<template>
  <div class="box-body">
    <div class="row">
      <incoming-tasks-box :incoming_tasks_count="statistic_count.incoming_tasks"/>

      <tasks-box :main_tasks_count="statistic_count.main_tasks"
                 :main_incoming_tasks="statistic_count.main_incoming_tasks"/>

      <control-task-box/>
<!--      <incoming-chats-box/>-->

      <create-task-box/>

      <cars-box :car_item="last_car_item" :cars_count="statistic_count.cars"/>

<!--      <sellers-tasks-box :sellers_tasks_count="statistic_count.sellers_tasks"/>-->

      <call-center-box :call_center_tasks_count="statistic_count.call_center_tasks"
                       :estimate_tasks_count="statistic_count.estimate_tasks"/>

      <credit-tasks-box :credit_tasks_count="statistic_count.credit_tasks"/>

      <exchange-tasks-box :exchange_tasks_count="statistic_count.exchange_tasks"
                          :search_tasks_count="statistic_count.search_tasks"/>

      <detailing-tasks-box v-if="[1, 7, 8, 9, 14].includes($store.getters['auth/authUser'].role.id)"
                           :detailing_tasks_count="statistic_count.detailing_tasks"/>

      <reports-tasks-box :new_tasks_count="statistic_count.new_tasks"/>

      <finance-box v-if="[7, 8].includes($store.getters['auth/authUser'].role.id) || $store.getters['auth/authUser'].id === 1"/>

      <main-statistic-box/>

      <archive-cars-box :archive_car_item="last_archive_car_item" :archive_cars_count="statistic_count.archive_cars"/>

      <employees-box v-if="[1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)"
                     :employees_count="statistic_count.employees"/>

      <news-box v-if="[1, 7, 8, 4].includes($store.getters['auth/authUser'].role.id)"
                :news_item="last_news_item" :news_count="statistic_count.news"/>

      <seo-box v-if="[1, 7, 8, 5].includes($store.getters['auth/authUser'].role.id)"
               :seo_count="statistic_count.seo"/>

      <reviews-box v-if="[1, 7, 8, 4, 6].includes($store.getters['auth/authUser'].role.id)"
                   :reviews_count="statistic_count.reviews"/>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

Vue.component('incoming-tasks-box', (resolve) => {
  return require(['./incoming-tasks-box'], resolve);
});
Vue.component('tasks-box', (resolve) => {
  return require(['./tasks-box.vue'], resolve);
});
// Vue.component('tasks-personal-box', (resolve) => {
//   return require(['./tasks-personal-box.vue'], resolve);
// });
// Vue.component('incoming-chats-box', (resolve) => {
//   return require(['./incoming-chats-box'], resolve);
// });
Vue.component('control-task-box', (resolve) => {
  return require(['./control-task-box.vue'], resolve);
});
Vue.component('create-task-box', (resolve) => {
  return require(['./create-task-box.vue'], resolve);
});
Vue.component('cars-box', (resolve) => {
  return require(['./cars-box.vue'], resolve);
});
// Vue.component('sellers-tasks-box', (resolve) => {
//   return require(['./sellers-tasks-box'], resolve);
// });
Vue.component('call-center-box', (resolve) => {
  return require(['./call-center-box'], resolve);
});
Vue.component('credit-tasks-box', (resolve) => {
  return require(['./credit-tasks-box.vue'], resolve);
});
Vue.component('exchange-tasks-box', (resolve) => {
  return require(['./exchange-tasks-box.vue'], resolve);
});
Vue.component('detailing-tasks-box', (resolve) => {
  return require(['./detailing-tasks-box.vue'], resolve);
});
Vue.component('reports-tasks-box', (resolve) => {
  return require(['./reports-tasks-box.vue'], resolve);
});
Vue.component('finance-box', (resolve) => {
  return require(['./finance-box'], resolve);
});
Vue.component('main-statistic-box', (resolve) => {
  return require(['./main-statistic-box'], resolve);
});
Vue.component('archive-cars-box', (resolve) => {
  return require(['./archive-cars-box.vue'], resolve);
});
Vue.component('employees-box', (resolve) => {
  return require(['./employees-box.vue'], resolve);
});
Vue.component('news-box', (resolve) => {
  return require(['./news-box.vue'], resolve);
});
Vue.component('seo-box', (resolve) => {
  return require(['./seo-box.vue'], resolve);
});
Vue.component('reviews-box', (resolve) => {
  return require(['./reviews-box.vue'], resolve);
});

export default {
  name: 'boxes',

  components: {},

  props: {
    statistic_count: {
      type: Object,
      required: true,
    },
    last_car_item: {
      type: [Object, null],
      required: false,
    },
    last_archive_car_item: {
      type: [Object, null],
      required: false,
    },
    last_news_item: {
      type: [Object, null],
      required: false,
    },
  },

  data: () => ({}),

  async created() {
    try {

    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {},
}
</script>
